import { ActionButton, Button, Checkbox, CheckboxGroup, Content, Flex, Form, Grid, Heading, IllustratedMessage, ProgressCircle, SearchField, Text, TextField, View } from '@adobe/react-spectrum';
import { useIsSSR } from '@react-aria/ssr';
import { isAndroid, isIOS } from '@react-aria/utils';
import { useBreakpoint } from '@react-spectrum/utils';
import { SpectrumCheckboxGroupProps } from '@react-types/checkbox';
import { SpectrumFormProps } from '@react-types/form';
import { SpectrumTextFieldProps, TextFieldRef } from '@react-types/textfield';
import NoSearchResults from '@spectrum-icons/illustrations/NoSearchResults';
import CheckmarkCircleOutline from '@spectrum-icons/workflow/CheckmarkCircleOutline';
import LinkOut from '@spectrum-icons/workflow/LinkOut';
import aws from 'aws-sdk';
import { AnimatePresence, motion } from 'framer-motion';
import matter from 'gray-matter';
import type { NextPage } from 'next';
import Head from 'next/head';
import Image from 'next/image';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { remark } from 'remark';
import html from 'remark-html';

import Footer from '../components/Footer';
import JobPost from '../components/JobPost';
import LoadingState from '../components/LoadingState';
import heroImageDesktop from '../public/images/hero-1-desktop.webp';
import heroImageMobile from '../public/images/hero-1-mobile.webp';

export const listAllKeys = async (s3: aws.S3, params: aws.S3.ListObjectsV2Request, out: aws.S3.Object[] = []): Promise<aws.S3.Object[]> => {
  const { Contents, IsTruncated, NextContinuationToken } = await s3.listObjectsV2(params).promise();

  if (Contents === undefined) {
    throw new Error('`Contents` must not be `undefined`.');
  }

  out.push(...Contents);

  if (!IsTruncated) return out;

  return listAllKeys(s3, Object.assign(params, { ContinuationToken: NextContinuationToken }), out);
};

export interface Job {
  companyLogoFileName?: [string, string];
  companyName: string;
  companyWebsite: string;
  date: string;
  description: string;
  descriptionLanguage?: string;
  id: string;
  jobPostLink: string;
  location: string;
  salary: string;
  title: string;
  type: string;
}

interface Props {
  isDarkMode: boolean;
  jobs: Job[];
  jobTypes: string[];
  searchText: string;
  selectedJobTypes: string[];
  setSearchText: Dispatch<SetStateAction<string>>;
  setSelectedJobTypes: Dispatch<SetStateAction<string[]>>;
}

const title = 'Comics Jobs';
const description = 'Jobs for comics creators.';
const imageUrl = 'https://comics-jobs.com/images/logo-512.png';

const Home: NextPage<Props> = ({
  isDarkMode,
  jobs,
  jobTypes,
  searchText,
  selectedJobTypes,
  setSearchText,
  setSelectedJobTypes
}) => {
  const { matchedBreakpoints } = useBreakpoint();
  const isInBaseBreakpointRange = !matchedBreakpoints.includes('S');
  const isMobileDevice = isAndroid() || isIOS();
  const isSSR = useIsSSR();

  const emailFieldRef = useRef<TextFieldRef | null>(null);

  const [emailFieldIsInvalid, setEmailFieldIsInvalid] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isSubscribing, setIsSubscribing] = useState(false);

  const handleEmailFieldChange: SpectrumTextFieldProps['onChange'] = () => {
    if (emailFieldIsInvalid) {
      setEmailFieldIsInvalid(false);
    }
  };

  const handleSelectedJobTypesChange: SpectrumCheckboxGroupProps['onChange'] = value => {
    setSelectedJobTypes(value);
  };

  const handleSubmit: SpectrumFormProps['onSubmit'] = async event => {
    event.preventDefault();

    const emailField = emailFieldRef.current!;

    if (emailField.getInputElement()!.value === '' || !emailField.getInputElement()!.validity.valid) {
      setEmailFieldIsInvalid(true);
      emailField.focus();
      return;
    }

    setIsSubscribing(true);

    const url: RequestInfo = '/api/subscribe';

    const options: RequestInit = {
      body: JSON.stringify({ email: emailField.getInputElement()!.value }),
      method: 'POST'
    };

    try {
      const response = await fetch(url, options);

      if (!response.ok) {
        throw new Error(`${response.status} (${response.statusText})`);
      }

      setIsSubscribed(true);
    } catch (error) {
      setIsSubscribing(false);
      console.error(error);
    }
  };

  return (
    <>
      <Head>
        <title>
          {title}
        </title>
        <meta
          content={description}
          name="description"
        />
        <meta
          content="summary"
          name="twitter:card"
        />
        <meta
          content="@comics_jobs"
          name="twitter:site"
        />
        <meta
          content={title}
          name="twitter:title"
        />
        <meta
          content={description}
          name="twitter:description"
        />
        <meta
          content={imageUrl}
          name="twitter:image"
        />
        <meta
          content={title}
          name="og:title"
        />
        <meta
          content={description}
          name="og:description"
        />
        <meta
          content={imageUrl}
          name="og:image"
        />
        <meta
          content="https://comics-jobs.com"
          name="og:url"
        />
      </Head>
      {isSSR ? <LoadingState /> : (
        <View paddingX={{ base: 'size-100', S: 'size-200' }}>
          <Grid
            {...isInBaseBreakpointRange && {
              UNSAFE_style: {
                boxSizing: 'border-box',
                paddingBottom: 'var(--spectrum-global-dimension-size-700)',
                paddingLeft: 'var(--spectrum-global-dimension-size-100)',
                paddingRight: 'var(--spectrum-global-dimension-size-100)'
              }
            }}
            alignContent="center"
            height={{ base: `calc(100vh - ${isMobileDevice ? 70 : 56}px)`, S: 'size-6000' }}
            justifyContent="center"
            justifyItems="center"
            marginBottom={{ base: 'size-100', S: 'size-800' }}
            marginX={{ base: isMobileDevice ? -10 : -8, S: isMobileDevice ? -20 : -16 }}
            position="relative"
          >
            <Image
              alt="Photo of incomplete manga pages on a table."
              layout="fill"
              objectFit="cover"
              placeholder="blur"
              priority
              quality={100}
              {...isInBaseBreakpointRange ? {
                src: heroImageMobile
              } : {
                objectPosition: 'center 25%',
                src: heroImageDesktop
              }}
            />
            <Heading
              UNSAFE_style={{
                backgroundColor: 'var(--spectrum-global-color-gray-100)',
                color: 'var(--spectrum-alias-heading-text-color)',
                lineHeight: 'var(--spectrum-alias-heading-text-line-height)',
                textAlign: 'center',
                ...isInBaseBreakpointRange ? {
                  fontSize: 'var(--spectrum-alias-heading2-text-size)',
                  padding: 'var(--spectrum-global-dimension-size-100)'
                } : {
                  fontSize: 'var(--spectrum-alias-heading-display2-text-size)',
                  padding: 'var(--spectrum-global-dimension-size-100) var(--spectrum-global-dimension-size-200)'
                }
              }}
              level={1}
              marginBottom="heading-margin-bottom"
              marginTop="size-0"
              zIndex={1}
            >
              {'The free job board for comics creators.'}
            </Heading>
            <Heading
              UNSAFE_style={{
                backgroundColor: 'var(--spectrum-global-color-gray-100)',
                color: 'var(--spectrum-alias-heading-text-color)',
                lineHeight: 'var(--spectrum-alias-heading-text-line-height)',
                textAlign: 'center',
                ...isInBaseBreakpointRange ? {
                  fontSize: 'var(--spectrum-alias-heading4-text-size)',
                  padding: 'var(--spectrum-global-dimension-size-100)'
                } : {
                  fontSize: 'var(--spectrum-alias-heading2-text-size)',
                  padding: 'var(--spectrum-global-dimension-size-100) var(--spectrum-global-dimension-size-200)'
                }
              }}
              level={2}
              marginBottom="size-0"
              marginTop="size-0"
              zIndex={1}
            >
              {`${jobs.length} jobs posted.`}
            </Heading>
          </Grid>
          <Grid
            alignItems={{ S: 'start' }}
            columnGap={{ S: 'size-200' }}
            columns={{
              L: isMobileDevice ? ['minmax(0, 2fr)', 'minmax(0, 1fr)'] : ['minmax(0, 1fr)', 'minmax(0, 3fr)', 'minmax(0, 1fr)'],
              M: isMobileDevice ? ['1fr'] : ['minmax(0, 2fr)', 'minmax(0, 1fr)'],
              S: ['1fr']
            }}
            marginX={{ S: 'auto' }}
            maxWidth={{ S: 1280 }}
            rowGap={{ base: 'size-100', S: isMobileDevice ? 'size-100' : 'size-0' }}
          >
            <View
              backgroundColor="gray-50"
              borderColor="light"
              borderRadius="regular"
              borderWidth="thin"
              elementType="aside"
              padding="size-200"
              top={{ S: 'size-1200' }}
              {...isMobileDevice ? {
                isHidden: { L: true },
                order: { base: 2 }
              } : {
                isHidden: { L: false, S: true },
                order: { base: 2, S: 1 },
                position: { S: 'sticky' }
              }}
            >
              <SearchField
                label="Search jobs, companies and locations"
                marginBottom="size-200"
                onChange={setSearchText}
                value={searchText}
                width="100%"
              />
              <CheckboxGroup
                label="Type"
                onChange={handleSelectedJobTypesChange}
                value={selectedJobTypes}
              >
                {jobTypes.map(jobType => (
                  <Checkbox
                    key={jobType}
                    value={jobType}
                  >
                    {jobType}
                  </Checkbox>
                ))}
              </CheckboxGroup>
            </View>
            <View
              elementType="main"
              order={isMobileDevice ? { base: 2, L: 1 } : { base: 3, S: 2 }}
            >
              {jobs
                .filter(job =>
                  job.title.toLowerCase().includes(searchText.toLowerCase()) ||
                  job.companyName.toLowerCase().includes(searchText.toLowerCase()) ||
                  job.location.toLowerCase().includes(searchText.toLowerCase())
                )
                .filter(job => selectedJobTypes.includes(job.type))
                .length === 0 ? (
                  <IllustratedMessage
                    height="auto"
                    marginTop="size-400"
                  >
                    <NoSearchResults />
                    <Heading>
                      {'No matching results'}
                    </Heading>
                    <Content>
                      {'Try another search.'}
                    </Content>
                  </IllustratedMessage>
                ) : (
                  <Grid rowGap="size-100">
                    {jobs
                      .filter(job =>
                        job.title.toLowerCase().includes(searchText.toLowerCase()) ||
                        job.companyName.toLowerCase().includes(searchText.toLowerCase()) ||
                        job.location.toLowerCase().includes(searchText.toLowerCase())
                      )
                      .filter(job => selectedJobTypes.includes(job.type))
                      .map(job => (
                        <JobPost
                          key={job.id}
                          isDarkMode={isDarkMode}
                          job={job}
                          searchText={searchText}
                        />
                      ))
                    }
                  </Grid>
                )
              }
            </View>
            <View
              elementType="aside"
              top={{ S: 'size-1200' }}
              {...isMobileDevice ? {
                order: { base: 1, L: 2 },
                position: { L: 'sticky' }
              } : {
                isHidden: { M: false, S: true },
                order: { base: 1, S: 3 },
                position: { S: 'sticky' }
              }}
            >
              <View
                backgroundColor="gray-50"
                borderColor="light"
                borderRadius="regular"
                borderWidth="thin"
                marginBottom="size-100"
                padding="size-200"
              >
                <AnimatePresence
                  exitBeforeEnter
                  initial={false}
                >
                  {isSubscribed ? (
                    <motion.div
                      key="1"
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      initial={{ opacity: 0 }}
                    >
                      <Flex
                        alignItems="center"
                        direction="column"
                        justifyContent="center"
                      >
                        <CheckmarkCircleOutline
                          marginBottom="size-160"
                          marginTop="size-50"
                          size="XL"
                        />
                        <Heading
                          UNSAFE_style={{
                            fontSize: 'var(--spectrum-alias-heading2-text-size)',
                            fontWeight: 'var(--spectrum-alias-heading-text-font-weight-quiet)',
                            lineHeight: 'var(--spectrum-alias-heading-text-line-height)'
                          }}
                          level={3}
                          marginBottom="size-50"
                          marginTop="size-0"
                        >
                          {'Subscribed!'}
                        </Heading>
                        <Text
                          UNSAFE_style={{
                            fontSize: 'var(--spectrum-alias-font-size-default)',
                            fontStyle: 'var(--spectrum-global-font-style-italic)',
                            fontWeight: 'var(--spectrum-alias-body-text-font-weight)',
                            lineHeight: 'var(--spectrum-alias-body-text-line-height)',
                            textAlign: 'center'
                          }}
                          marginBottom="size-50"
                        >
                          {'A list of comics jobs will be sent to your inbox every Tuesday at 4:00 PM.'}
                        </Text>
                      </Flex>
                    </motion.div>
                  ) : (
                    <motion.div
                      key="2"
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      initial={{ opacity: 0 }}
                    >
                      <View marginBottom="size-100">
                        {'Enter your email address to receive comics jobs every week.'}
                        <br />
                        {'Unsubscribe anytime.'}
                      </View>
                      <Form
                        aria-label=""
                        isRequired
                        minWidth="auto"
                        onSubmit={handleSubmit}
                      >
                        <TextField
                          ref={emailFieldRef}
                          aria-label="Email"
                          inputMode="email"
                          onChange={handleEmailFieldChange}
                          type="email"
                          validationState={emailFieldIsInvalid ? 'invalid' : undefined}
                          width="100%"
                        />
                        <ActionButton
                          isDisabled={isSubscribing}
                          type="submit"
                          width="100%"
                        >
                          {isSubscribing ? (
                            <ProgressCircle
                              aria-label="Loading..."
                              isIndeterminate
                              size="S"
                              variant="overBackground"
                            />
                          ) : 'Subscribe'}
                        </ActionButton>
                      </Form>
                    </motion.div>
                  )}
                </AnimatePresence>
              </View>
              <View
                backgroundColor="gray-50"
                borderColor="light"
                borderRadius="regular"
                borderWidth="thin"
                padding="size-200"
              >
                <View marginBottom="size-200">
                  {'Need a website for your webcomic? Create one now on webcomic.app.'}
                </View>
                <Button
                  elementType="a"
                  href="https://webcomic.app"
                  target="_blank"
                  variant="cta"
                  width="100%"
                >
                  <LinkOut />
                  <Text>
                    {'Visit webcomic.app'}
                  </Text>
                </Button>
              </View>
            </View>
          </Grid>
          <Grid
            columnGap={{ S: 'size-200' }}
            columns={{
              L: isMobileDevice ? ['1fr'] : ['minmax(0, 1fr)', 'minmax(0, 3fr)', 'minmax(0, 1fr)'],
              S: ['1fr']
            }}
            marginBottom={{ base: 'size-200', S: 'size-400' }}
            marginTop={{ base: 'size-1000', S: 'size-1600' }}
            marginX={{ S: 'auto' }}
            maxWidth={{ S: 1280 }}
          >
            <View isHidden={isMobileDevice ? { S: true } : { L: false, S: true }} />
            <Footer isOnHomePage />
            <View isHidden={isMobileDevice ? { S: true } : { M: false, S: true }} />
          </Grid>
        </View>
      )}
    </>
  );
};

export const getStaticProps = async () => {
  aws.config.update({
    credentials: {
      accessKeyId: process.env.AWS_ACCESS_KEY_ID_COMICS_JOBS,
      secretAccessKey: process.env.AWS_SECRET_ACCESS_KEY_COMICS_JOBS
    },
    region: 'us-west-000',
    signatureVersion: 'v4'
  });

  const endpoint = 's3.us-west-000.backblazeb2.com';

  if (process.env.NODE_ENV === 'development') {
    const { default: chalk } = await import('chalk');

    try {
      const AMAZON_ORANGE_COLOR: [number, number, number] = [255, 153, 0];

      aws.config.logger = {
        ...console,
        log: (...args: Parameters<typeof console.log>) => {
          // eslint-disable-next-line no-console
          console.log(chalk.rgb(...AMAZON_ORANGE_COLOR)(args[0]));
        }
      };
    } catch (error_) {
      const error = error_ as Error;

      console.error(error);
    }
  }

  const s3 = new aws.S3({ endpoint });

  const bucket = 'comics-jobs';

  const keys = await listAllKeys(s3, { Bucket: bucket });

  const fileNames = keys.map(key => key.Key!).filter(fileName => fileName.endsWith('.md'));

  const jobs: Job[] = await Promise.all(
    fileNames.map(async fileName => {
      const id = fileName.split('/')[1].replace(/\.md$/, '');

      const fileContents = await s3.getObject({ Bucket: bucket, Key: fileName }).promise();
      const matterResult = matter(fileContents.Body!.toString());

      const processedContent = await remark()
        .use(html)
        .process(matterResult.content);

      const contentHtml = processedContent.toString();

      return {
        description: contentHtml,
        id,
        ...matterResult.data as {
          companyLogoFileName?: [string, string];
          companyName: string;
          companyWebsite: string;
          date: string;
          descriptionLanguage?: string;
          jobPostLink: string;
          location: string;
          salary: string;
          title: string;
          type: string;
        }
      };
    })
  );

  jobs.sort(({ date: a }, { date: b }) => {
    if (a < b) return 1;
    else if (a > b) return -1;
    else return 0;
  });

  return { props: { jobs } };
};

export default Home;
